import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Can from '../../../components/Can';
import LoadingButton from '../../../components/LoadingButton';
import ConfirmationDialogOnClickWrapper from '../../../components/ConfirmationDialogOnClickWrapper';
import { HOLD_PATIENT_PREFIX, holdPatientAction } from '../../../reducers/patients';
import createLoadingSelector from '../../../selectors/loading';
import ActionOwnerExtraInfo from './ActionOwnerExtraInfo';
import InfoDialog from '../../../components/InfoDialog';
import OffHoldWrapper from './OffHoldWrapper';

const PatientHoldingAction = ({ patient, isLoading }) => {
  const dispatch = useDispatch();
  const isHolding = useSelector(state => createLoadingSelector([HOLD_PATIENT_PREFIX])(state));
  const isManagement = useSelector(state => state.auth.isManagement);
  const [extraHoldingDataRequesting, setExtraHoldingDataRequesting] = useState(false);
  const [infoDialog, setInfoDialog] = useState(false);

  const holdPatient = extraInfo => {
    dispatch(holdPatientAction(patient.id, extraInfo));
    return true;
  };

  const confirmHold = () => {
    if (patient.rx_form && patient.rx_form.completed_at && !isManagement) {
      setInfoDialog(true);
    } else {
      setExtraHoldingDataRequesting(true);
    }
  };

  if (patient && patient.deleted_at) {
    return null;
  }

  return (
    <>
      <Can
        organizationId={patient.organization_id}
        permissions={[
          'patients.manage',
          'patients.supervise',
          'patients.toggle-hold',
          'organization.patients.toggle-hold'
        ]}
        yes={() => (
          <>
            <OffHoldWrapper patient={patient}>
              {({ disabled: disabledSavedForLater }) => (
                <ConfirmationDialogOnClickWrapper
                  confirmationBody={`Are you sure you want to ${
                    patient.is_on_hold ? 'unhold' : 'hold'
                  } the current patient?`}
                  confirmationTitle={patient.is_on_hold ? 'Unhold The Patient' : 'Put on Hold'}
                >
                  <LoadingButton
                    loading={isHolding}
                    color="secondary"
                    variant={disabledSavedForLater ? 'contained' : 'outlined'}
                    disabled={isLoading || isHolding || disabledSavedForLater}
                    onClick={confirmHold}
                  >
                    {patient.is_on_hold ? 'Unhold' : 'Hold'}
                  </LoadingButton>
                </ConfirmationDialogOnClickWrapper>
              )}
            </OffHoldWrapper>
            <ActionOwnerExtraInfo
              open={extraHoldingDataRequesting}
              setOpen={open => setExtraHoldingDataRequesting(open)}
              onAddedExtraInfo={extraInfo => holdPatient(extraInfo)}
              title={patient.is_on_hold ? 'Put Off Hold' : 'Put On Hold'}
              hasNotifyField={!patient.is_on_hold}
              infoMessage={
                patient.is_on_hold
                  ? ''
                  : 'Please Note: Placing this case On Hold does not remove fees if the work has been completed.'
              }
            />
            <InfoDialog
              title="Put on Hold"
              content="Patient has completed initial setup. Please call and request a cancellation for cases after setup."
              open={infoDialog}
              onClose={setInfoDialog}
            />
          </>
        )}
      />
    </>
  );
};

PatientHoldingAction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default PatientHoldingAction;
