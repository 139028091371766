import { Query } from 'cogent-js';
import BaseApiService from './base';

export default class BundledShipmentApiService extends BaseApiService {
  constructor() {
    super();
    this.query = new Query();
  }

  update(bundledShipmentId, trackingNumber, customShippingFee = null, internalShippingCost = null) {
    return this.request({
      method: 'patch',
      url: `/bundled-shipments/${bundledShipmentId}`,
      data: {
        tracking_number: trackingNumber,
        custom_shipping_fee: customShippingFee,
        internal_shipping_cost: internalShippingCost
      }
    });
  }
}
