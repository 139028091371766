import React from 'react';
import PatientLabelDialog from './Dialogs/PatientLabelDialog';
import ShippingLabelDialog from './Dialogs/ShippingLabelDialog';
import BundleCompletionDialog from './Dialogs/BundleCompletionDialog';
import UpdateBundleShippingDialog from './Dialogs/UpdateBundledShippingDialog';

const ShipmentDialog = () => {
  return (
    <>
      <PatientLabelDialog />
      <ShippingLabelDialog />
      <BundleCompletionDialog />
      <UpdateBundleShippingDialog />
    </>
  );
};

ShipmentDialog.propTypes = {};

export default ShipmentDialog;
