import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { produce } from 'immer';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, get, each } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Badge, Button } from '@material-ui/core';
import { blueGrey, green } from '@material-ui/core/colors';
import { ToggleButtonGroup } from '@material-ui/lab';
import ToggleButton from '@material-ui/lab/ToggleButton';
import WorkOutlineIcon from '@material-ui/icons/WorkOutlineSharp';
import WorkIcon from '@material-ui/icons/WorkSharp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDownSharp';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUpSharp';
import { Print } from '@material-ui/icons';
import isEmpty from 'lodash/isEmpty';
import DataTable from '../../components/DataTable';
import createLoadingSelector from '../../selectors/loading';
import SearchInput from '../../components/SearchInput';
import PatientsFilter from '../../components/PatientsFilter';
import {
  FETCH_PATIENTS_PREFIX,
  fetchPatientsAction,
  initialState,
  setInitialParameters,
  showPatientLabel
} from '../../reducers/patients';
import { findRolesInUser, isCompletedPatient, isSuperAdminOrAdmin } from '../../helpers';
import DibsRangePickerFilter from '../../components/DibsRangePickerFilter';
import Content from '../../components/Layout/Content';
import { REQUEST, REQUEST_STATUS, ROLES } from '../../constants';
import extractUrlQueryParameters from './helpers/extractUrlQueryParameters';
import { openPatientModificationWarning } from '../../reducers/modals';
import PatientModificationWarning from '../../components/PatientModificationWarning';
import PatientColumn from './columns/PatientColumn';
import DateColumn from './columns/DateColumn';
import RequestsColumn from './columns/RequestsColumn';
import ShippingColumn from './columns/ShippingColumn';
import checkLikOrButton from './helpers/checkLikOrButton';
import withIdleDetector from '../../hooks/withIdleDetector';
import ShipmentDialog from '../../components/Shipment/ShipmentDialog';
import { COMMON_PATIENT_INCLUDES } from '../../constants/queries';
import RowsIterator from './components/RowsIterator';
import { isShipmentBundlingEnabled } from './components/ShipmentBundling/functions';
import { SORT_VALUE as BUNDLE_SHIPMENT_SORT_VALUE } from './components/ShipmentBundling/constants';
import NoteStatus from './components/NoteStatus';
import OpenWithExternalApp from '../../components/OpenWithExternalApp';
import { openDIBS } from '../../helpers/dibs';
import { formatProductionTime } from '../../helpers/techWorkflow';
import ExpandCollapseIconButton from '../../components/IconButtons/ExpandCollapseIconButton';
import ScannerType from './components/ScannerType';
import TrackingNumber from './components/TrackingNumber';
import { refineParameters } from './helpers/parametersRefinement';

const useStyles = makeStyles(theme => ({
  date: {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      display: 'inline-block',
      fontSize: 12
    }
  },
  dibsButtonBadge: {
    marginRight: theme.spacing(1),
    '& .MuiBadge-badge': {
      height: 'auto',
      fontSize: 9,
      padding: 3,
      color: 'white'
    }
  },
  dibsButtonBadgeStl: {
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.success.main
    }
  },
  dibsButtonBadgeIos: {
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.primary.main
    }
  },
  compact: {
    maxWidth: 1400,
    '& .MuiTableCell-root.MuiTableCell-body': {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5)
    }
  },
  collapsedRow: {
    '& > td.MuiTableCell-root.MuiTableCell-body': {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      verticalAlign: 'top'
    }
  },
  compactButtons: {
    margin: theme.spacing(0.5)
  },
  dibsButton: {},
  workflowGrid: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  statusTag: {
    width: '100%',
    marginLeft: 0
  },
  workflowItem: {
    minHeight: 24
  },
  switch: {
    paddingTop: 0,
    paddingRight: 0
  },
  time: {
    color: theme.palette.primary.main,
    fontWeight: '500',
    display: 'block',
    marginBottom: '0.5rem',
    marginLeft: '0.85rem'
  },
  patientDetailsLink: {
    display: 'flex',
    flexDirection: 'column'
  },
  organizationDetailsLink: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 10,
    color: 'gray'
  },
  assignedTo: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    backgroundColor: blueGrey[600]
  },
  requestItem: {
    display: 'flex'
  },
  buttonFilter: {
    marginLeft: theme.spacing(1)
  },
  miscButtons: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'flex-end'
  },
  refreshButton: {},
  resetButton: {
    display: 'inline-flex'
  },
  greenButton: {
    color: green[500]
  }
}));

const REFRESH_INTERVAL = 60 * 2; // seconds

const PatientsPage = ({ idle, location, match: { params } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const globalParams = useSelector(state => state.patients.globalParams);
  const customGlobalParamsInitialized = useSelector(
    state => state.patients.customGlobalParams !== null
  );
  const is3PP = useSelector(state => findRolesInUser([ROLES.labPartner], state.auth.user));
  const isLoading = useSelector(state => createLoadingSelector([FETCH_PATIENTS_PREFIX])(state));
  const selectedOrganizations = useSelector(state => state.auth.management.selectedOrganizations);
  const urlQueryParams = new URLSearchParams(location.search);
  const paramsFromUrl = extractUrlQueryParameters(location.search);
  const refreshTimes = useRef(0);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [keywordSearchOnly, setKeywordSearchOnly] = useState(false);
  const currentUser = useSelector(state => state.auth.user);
  const isAdmin = currentUser ? isSuperAdminOrAdmin(currentUser) : false;

  const initialParamsFor3PP = {
    requestAssignments: []
  };

  const [additionalParams, setAdditionalParams] = useState({
    params: {
      ...globalParams,
      ...paramsFromUrl
    },
    resetPage: false
  });

  const [collapsed, setCollapsed] = useState([]);
  const [isCollapseAll, setIsCollapseAll] = useState(true);
  const searchRefs = {
    patient: useRef(null),
    organization: useRef(null),
    orgType: useRef(null),
    trackingNumber: useRef(null)
  };

  const [selectedApp, setSelectedApp] = useState(null);

  const rows = useSelector(state => state.patients.items);
  const total = useSelector(state => state.patients.total);

  const removeQueryParam = useCallback(
    (...paramsToRemove) => {
      let paramsRemoved = false;

      each(paramsToRemove, param => {
        if (urlQueryParams.has(param)) {
          urlQueryParams.delete(param);
          paramsRemoved = true;
        }
      });

      if (paramsRemoved) {
        history.replace({
          search: urlQueryParams.toString()
        });
      }
    },
    [urlQueryParams, history]
  );

  useEffect(() => {
    removeQueryParam(...Array.from(urlQueryParams.keys()));
  }, [urlQueryParams, removeQueryParam]);

  useEffect(() => {
    if (!isCollapseAll) {
      setCollapsed([...rows.map(r => r.id)]);
    } else {
      setCollapsed([]);
    }
  }, [rows, isCollapseAll]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const updatedParams = extractUrlQueryParameters(queryParams);
    if (Object.keys(updatedParams).length) {
      setAdditionalParams({
        params: {
          ...initialState.globalParams,
          ...initialState.customGlobalParams,
          ...updatedParams
        },
        resetPage: true
      });
    }
  }, [urlQueryParams, location]);

  const getSortValue = name => {
    return additionalParams.params.orderBy === name ? additionalParams.params.order : null;
  };

  const handleSort = useCallback(
    ({ property, direction }) => {
      setAdditionalParams(
        produce(additionalParams, draft => {
          if (!direction) {
            draft.params.order = initialState.globalParams.order;
            draft.params.orderBy = initialState.globalParams.orderBy;
          } else {
            draft.params.order = direction;
            draft.params.orderBy = property;
          }
          draft.resetPage = true;
        })
      );
    },
    [additionalParams, setAdditionalParams]
  );

  useEffect(() => {
    if (
      globalParams.orderBy === BUNDLE_SHIPMENT_SORT_VALUE &&
      globalParams.orderBy !== additionalParams.params.orderBy
    ) {
      handleSort({
        property: BUNDLE_SHIPMENT_SORT_VALUE,
        direction: 'asc'
      });
    }
  }, [globalParams.orderBy, additionalParams.params.orderBy, handleSort]);

  const createSortHandler = column => (event, value) => {
    handleSort({ property: column, direction: value });
  };

  const handleUpdateData = useCallback(
    parameters => {
      if (!customGlobalParamsInitialized) {
        return;
      }
      setCollapsed([]);
      refineParameters(parameters, { is3PP });

      const includes = [...COMMON_PATIENT_INCLUDES];

      if (isShipmentBundlingEnabled(parameters)) {
        includes.push('customer_request.printing_request.bundled_shipment.shipping_cost');
      }

      let temporaryParams = {};
      if (keywordSearchOnly) {
        temporaryParams = {
          searchFullNameQuery: parameters.searchFullNameQuery,
          searchDoctorFullNameQuery: parameters.searchDoctorFullNameQuery,
          searchDoctorFullNameOrOrganizationQuery:
            parameters.searchDoctorFullNameOrOrganizationQuery,
          trackingNumber: parameters.trackingNumber,
          organizationId: parameters.organizationId,
          userId: parameters.userId,
          page: parameters.page,
          perPage: parameters.perPage,
          withOnhold: true,
          onlyOnhold: 'with',
          activeRequestOnly: false,
          requestAssignments: [],
          requestTypeStatus: [],
          archived: 'with',
          includes,
          organizations: selectedOrganizations.map(org => org.id)
        };
      }

      dispatch(
        fetchPatientsAction(
          {
            ...parameters,
            includes,
            organizations: selectedOrganizations.map(org => org.id)
          },
          temporaryParams
        )
      );
    },
    [dispatch, selectedOrganizations, is3PP, customGlobalParamsInitialized, keywordSearchOnly]
  );

  const [currentPatient, setCurrentPatient] = useState(null);

  const onPatientModificationWarningClose = answer => {
    if (answer) {
      const fileId = currentPatient.ios_file_id;
      if (fileId) {
        dispatch(openDIBS(selectedApp, `open_file/${fileId}`));
      }
    }
  };

  const handleShowPatientWorkflow = id => {
    history.push(`/patient-workflow/${id}`);
  };

  // eslint-disable-next-line no-shadow
  const handleReset = (params = {}) => {
    removeQueryParam(...Array.from(urlQueryParams.keys()));
    Object.keys(searchRefs).forEach(key => {
      searchRefs[key].current.value = '';
    });
    setAdditionalParams({
      params: {
        ...initialState.globalParams,
        ...initialState.customGlobalParams,
        ...params
      },
      resetPage: true
    });
  };

  const handleTrackingNumberSearch = trackingNumber => {
    handleReset({
      trackingNumber,
      activeRequestOnly: false,
      requestTypeStatus: [`${REQUEST.PRINT}.${REQUEST_STATUS.COMPLETED}`]
    });
  };

  const handleFilterChange = debounce((value, additionalParameterKey) => {
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params = {
          ...additionalParams.params,
          [additionalParameterKey]: value
        };
        draft.resetPage = true;
      })
    );
  }, 250);

  const createToggleHandler = ({ onlyOrWithout = false, nullable = false } = {}) => event => {
    let value;
    if (onlyOrWithout) {
      value = event.target.checked ? 'only' : 'without';
    } else {
      value = event.target.checked;
    }
    if (nullable && !value) {
      value = null;
    }
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params[event.target.name] = value;
        draft.resetPage = true;
      })
    );
  };

  const refresh = useCallback(() => {
    refreshTimes.current += 1;
    setAdditionalParams(
      produce(additionalParams, draft => {
        draft.params = { ...additionalParams.params };
      })
    );
  }, [setAdditionalParams, additionalParams]);

  const toggleAutoRefresh = () => {
    setAutoRefresh(!autoRefresh);
  };

  const toggleKeywordSearchOnly = () => {
    setKeywordSearchOnly(!keywordSearchOnly);
  };

  useEffect(() => {
    if (!idle || !autoRefresh) {
      refreshTimes.current = 0;
      return () => {};
    }
    if (refreshTimes.current === 0) {
      refresh();
      return () => {};
    }

    const timeout = setTimeout(refresh, REFRESH_INTERVAL * 1000);

    return () => clearTimeout(timeout);
  }, [idle, refresh, autoRefresh]);

  // initialize custom parameters
  useEffect(() => {
    if (customGlobalParamsInitialized) {
      return;
    }
    const initialParams = is3PP ? initialParamsFor3PP : {};
    dispatch(setInitialParameters(initialParams));
    setAdditionalParams({
      params: {
        ...globalParams,
        ...initialParams,
        ...paramsFromUrl
      },
      resetPage: false
    });
  }, [
    is3PP,
    globalParams,
    paramsFromUrl,
    initialParamsFor3PP,
    dispatch,
    customGlobalParamsInitialized
  ]);

  const renderFilters = () => {
    const sortLabel = isShipmentBundlingEnabled(globalParams) ? 'Shipments' : 'Cases';

    return (
      <>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="flex-end">
              <Grid container alignItems="flex-end" spacing={2}>
                <Grid item xs={6} md={4}>
                  <SearchInput
                    placeholder="Search by Patient"
                    inputRef={searchRefs.patient}
                    ignore={[',']}
                    value={additionalParams.params.searchFullNameQuery}
                    onChange={e => handleFilterChange(e.target.value, 'searchFullNameQuery')}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <SearchInput
                    disabled={
                      additionalParams.params.userId !== null ||
                      additionalParams.params.organizationId !== null
                    }
                    inputRef={searchRefs.organization}
                    placeholder="Search by Doctor or Organization"
                    value={additionalParams.params.searchDoctorFullNameOrOrganizationQuery}
                    ignore={[',']}
                    delay={250}
                    onChange={e =>
                      handleFilterChange(e.target.value, 'searchDoctorFullNameOrOrganizationQuery')
                    }
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <SearchInput
                    inputRef={searchRefs.orgType}
                    placeholder="Search by Organization Type"
                    value={globalParams.customerServiceManager}
                    ignore={[',']}
                    onChange={e => handleFilterChange(e.target.value, 'customerServiceManager')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="end" justifyContent="space-between">
                    <Grid item xs={4}>
                      <SearchInput
                        inputRef={searchRefs.trackingNumber}
                        placeholder="Search by Tracking Number"
                        value={additionalParams.params.trackingNumber}
                        onChange={e => handleFilterChange(e.target.value, 'trackingNumber')}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel
                        checked={keywordSearchOnly}
                        onChange={toggleKeywordSearchOnly}
                        control={<Switch color="primary" />}
                        label="Search By Keyword Only"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!keywordSearchOnly && (
                  <>
                    <Grid item xs={12}>
                      <PatientsFilter
                        handleChange={handleFilterChange}
                        filterValues={globalParams}
                        initialValues={additionalParams.params}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box pr={1} display="flex" justifyContent="end">
                        <Typography component="span" variant="subtitle1">
                          Expected Shipping Date&nbsp;
                        </Typography>
                        <DibsRangePickerFilter
                          setAdditionalParams={setAdditionalParams}
                          additionalParams={additionalParams}
                          fromParamKey="shipDateFrom"
                          toParamKey="shipDateTo"
                          convertToUtc={false}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      justifyContent="flex-end"
                      className={classes.switch}
                    >
                      {isAdmin ? (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={additionalParams.params.withOnhold === true}
                              onChange={createToggleHandler()}
                              name="withOnhold"
                            />
                          }
                          label="On-hold"
                        />
                      ) : (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={additionalParams.params.onlyOnhold === 'only'}
                              onChange={createToggleHandler({ onlyOrWithout: true })}
                              name="onlyOnhold"
                            />
                          }
                          label="Only On-hold"
                        />
                      )}
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!!additionalParams.params.activeRequestOnly}
                            onChange={createToggleHandler({ nullable: true })}
                            name="activeRequestOnly"
                          />
                        }
                        label="Active Only"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!!additionalParams.params.rushCaseOnly}
                            onChange={createToggleHandler()}
                            name="rushCaseOnly"
                          />
                        }
                        label="Rush Case Only"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!!additionalParams.params.shipping}
                            onChange={createToggleHandler({ nullable: true })}
                            name="shipping"
                          />
                        }
                        label="Shipping"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={additionalParams.params.archived === 'only'}
                            onChange={createToggleHandler({ onlyOrWithout: true })}
                            name="archived"
                          />
                        }
                        label="Archived Only"
                      />
                      <FormControlLabel
                        control={
                          <Switch
                            checked={!!additionalParams.params.doctorNotesOnly}
                            onChange={createToggleHandler()}
                            name="doctorNotesOnly"
                          />
                        }
                        label="Doctor Notes Only"
                      />
                      <ToggleButtonGroup
                        color="primary"
                        size="small"
                        value={getSortValue(
                          isShipmentBundlingEnabled(globalParams)
                            ? BUNDLE_SHIPMENT_SORT_VALUE
                            : 'created_at'
                        )}
                        exclusive
                        onChange={createSortHandler(
                          isShipmentBundlingEnabled(globalParams)
                            ? BUNDLE_SHIPMENT_SORT_VALUE
                            : 'created_at'
                        )}
                      >
                        <ToggleButton value="desc">Newest {sortLabel}</ToggleButton>
                        <ToggleButton value="asc">Oldest {sortLabel}</ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} className={classes.miscButtons}>
                  <ExpandCollapseIconButton
                    expandTitle="Expand All"
                    collapseTitle="Collapse All"
                    onClick={isCollapse => {
                      setIsCollapseAll(isCollapse);
                    }}
                    collapsed={isCollapseAll}
                  />
                  <FormControlLabel
                    checked={autoRefresh}
                    onChange={toggleAutoRefresh}
                    control={<Switch name="auto-refresh" color="primary" />}
                    label="Auto-Refresh"
                  />
                  <Button color="primary" title="Refresh" onClick={refresh} variant="contained">
                    Refresh
                  </Button>
                  <Button
                    color="secondary"
                    title="Reset Filters"
                    onClick={handleReset}
                    variant="contained"
                  >
                    Reset Filters
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };

  const isCollapsed = patientId => {
    return collapsed.find(item => patientId === item) !== undefined;
  };

  const newColumns = [
    {
      id: 'patient',
      label: 'Patient',
      customSortId: 'last_name',
      formatMethod: (value, row) => {
        return (
          <PatientColumn
            collapsed={isCollapsed(row.id)}
            patient={row}
            doctor={row.user}
            organization={row.organization}
          />
        );
      }
    },
    {
      id: 'date',
      label: 'Dates',
      customSortId: 'created_at',
      formatMethod: (value, row) => {
        return (
          <DateColumn
            started={row.created_at}
            modified={row.updated_at}
            completed={row.setup_completed_at}
            collapsed={isCollapsed(row.id)}
            completedLabel={
              row.workflow_data.print && !isEmpty(row.workflow_data.print)
                ? 'Approved'
                : 'Completed'
            }
          />
        );
      }
    },
    {
      id: 'production_time',
      label: 'Prod. Time',
      width: '100px',
      align: 'center',
      customSortId: 'overall_production_time',
      formatMethod: (value, row) => formatProductionTime(row.overall_production_time)
    },
    {
      id: 'requests',
      label: 'Requests',
      customSortId: 'request_status_updated_at',
      formatMethod: (value, row) => {
        const requestFilter = additionalParams.params.requestTypeStatus;
        const requests = [];
        if (requestFilter.length) {
          const [request] = requestFilter[0].split('.');
          requests.push(request);
        }
        return (
          <RequestsColumn
            collapsed={isCollapsed(row.id)}
            workflowData={row.workflow_data}
            requests={requests}
            assignments={additionalParams.params.requestAssignments}
          />
        );
      }
    },
    {
      id: 'shipping',
      label: 'Shipping',
      customSortId: isShipmentBundlingEnabled(globalParams)
        ? BUNDLE_SHIPMENT_SORT_VALUE
        : 'ship_date_sort',
      formatMethod: (value, row) => {
        return get(row, 'workflow_data.print.shipping_method') ? (
          <ShippingColumn
            collapsed={isCollapsed(row.id)}
            printingRequest={row.customer_request && row.customer_request.printing_request}
            onClickTrackingNumber={handleTrackingNumberSearch}
          />
        ) : (
          ''
        );
      }
    }
  ];

  newColumns.forEach(column => {
    if (column.disabledSorting) {
      return;
    }
    // disable column sort except for shipping if bundling shipment is enabled
    // eslint-disable-next-line no-param-reassign
    column.disabledSorting = () => {
      const sort = column.customSortId || column.id;
      return (
        isShipmentBundlingEnabled(globalParams) &&
        !['ship_date_sort', BUNDLE_SHIPMENT_SORT_VALUE].includes(sort)
      );
    };
  });

  const toggleCollapse = patientId => {
    if (isCollapsed(patientId)) {
      setCollapsed(collapsed.filter(item => item !== patientId));
    } else {
      setCollapsed([...collapsed, patientId]);
    }
  };

  const isClaimed = row => {
    const current = get(row, 'workflow_data.current', null);

    if (!current) {
      return false;
    }

    const nextStep = get(row, `workflow_data.${current}.next_step`, null);

    return nextStep && (nextStep.tech_name || nextStep.time_end || nextStep.time_end);
  };

  const externalAppOpener = useMemo(
    () => ({
      CustomComponent: ({ row }) => {
        const handleOpenExternal = (patient, app) => {
          setCurrentPatient(patient);
          setSelectedApp(app);

          if (isCompletedPatient(patient)) {
            dispatch(openPatientModificationWarning('PatientsPage-List'));
            return;
          }

          const fileId = patient.ios_file_id;
          if (fileId) {
            dispatch(openDIBS(app, `open_file/${fileId}`));
          } else if (patient.has_stl_file) {
            dispatch(openDIBS(app, `open_patient/${patient.id}`));
          }
        };

        return (
          <OpenWithExternalApp
            onClick={app => handleOpenExternal(row, app)}
            patient={row}
            buttonProps={{
              name: 'open',
              title: 'Open DIBS',
              className: classes.compactButton,
              disabled: !row.has_ios_file && !row.has_stl_file,
              size: 'small',
              variant: 'contained'
            }}
          />
        );
      },
      // eslint-disable-next-line react/prop-types
      Wrapper: ({ row, children }) => {
        let badgeLabel = null;
        const classNames = [classes.dibsButtonBadge];
        if (row.has_ios_file) {
          badgeLabel = 'IOS';
          classNames.push(classes.dibsButtonBadgeIos);
        } else if (row.has_stl_file) {
          badgeLabel = 'STL';
          classNames.push(classes.dibsButtonBadgeStl);
        }
        return (
          <Badge className={classNames.join(' ')} badgeContent={badgeLabel}>
            {children}
          </Badge>
        );
      }
    }),
    [classes, dispatch]
  );

  const newActionColumns = [
    externalAppOpener,
    {
      name: 'details',
      handleOnAction: handleShowPatientWorkflow,
      title: 'Workflows',
      isIconButton: true,
      className: row => {
        let c = classes.compactButton;

        if (isClaimed(row)) {
          c = `${c} ${classes.greenButton}`;
        }
        return c;
      },
      icon: row => (isClaimed(row) ? <WorkIcon /> : <WorkOutlineIcon />)
    },
    {
      name: 'patient_label',
      handleOnAction: (id, patient) => {
        dispatch(showPatientLabel(patient));
      },
      title: 'Patient Label',
      isIconButton: true,
      className: classes.compactButton,
      icon: <Print />,
      condition: () => {
        return isShipmentBundlingEnabled(globalParams);
      }
    },
    {
      name: 'more',
      handleOnAction: toggleCollapse,
      title: 'Show More',
      isIconButton: true,
      className: classes.compactButton,
      icon: <KeyboardArrowDownIcon />,
      condition: row => !isCollapsed(row.id)
    },
    {
      name: 'hide',
      handleOnAction: toggleCollapse,
      title: 'Hide',
      isIconButton: true,
      className: classes.compactButton,
      icon: <KeyboardArrowUpIcon />,
      condition: row => isCollapsed(row.id)
    },
    {
      name: 'noteStatus',
      title: 'Note Status',
      component: row => (
        <NoteStatus
          key={row.id}
          hasDoctorNotes={row.notes_availability.doctor}
          hasInternalNotes={row.notes_availability.internal}
          hasManagementNotes={row.notes_availability.tech}
          hasOnHoldNotes={row.notes_availability.onhold}
          hasPhysicalTechNotes={row.notes_availability.physical_tech}
        />
      ),
      condition: () => true
    },
    {
      name: 'scannerType',
      title: 'Scanner Type',
      component: row => <ScannerType rxForm={row.rx_form} />,
      condition: row => isCollapsed(row.id)
    },
    {
      name: 'trackingNumber',
      title: 'Tracking Number',
      component: row => (
        <TrackingNumber
          printingRequest={get(row, 'customer_request.printing_request')}
          onClickTrackingNumber={handleTrackingNumberSearch}
        />
      ),
      condition: row => isCollapsed(row.id)
    }
  ];

  const rowsPropsProvider = row => {
    const props = {};
    if (isCollapsed(row.id)) {
      props.className = classes.collapsedRow;
    }
    props.onMouseUp = e => {
      if (!checkLikOrButton(e.target)) {
        toggleCollapse(row.id);
      }
    };
    return props;
  };

  return (
    <Content filters={renderFilters()} className={classes.compact}>
      <DataTable
        isLoading={isLoading}
        columns={newColumns}
        rows={rows}
        total={total}
        updateData={handleUpdateData}
        defaultOrderBy={additionalParams.params.orderBy}
        additionalParams={additionalParams}
        showVerticalLines
        rowsPerPageOptions={[10, 25, 50, 100]}
        defaultRowsPerPage={50}
        customActions={newActionColumns}
        globalParams={globalParams}
        onSortChange={handleSort}
        rowPropsProvider={rowsPropsProvider}
        RowsIterator={RowsIterator}
        rowsIteratorProps={{
          enabledGroupShipment: isShipmentBundlingEnabled(globalParams)
        }}
      />
      <PatientModificationWarning
        id="PatientsPage-List"
        onClose={onPatientModificationWarningClose}
      />
      <ShipmentDialog />
    </Content>
  );
};

PatientsPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  idle: PropTypes.bool.isRequired
};

PatientsPage.defaultProps = {
  match: { params: {} }
};

export default withIdleDetector(PatientsPage, REFRESH_INTERVAL);
