import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { TextField } from 'formik-material-ui';
import { connect } from 'react-redux';
import FormDialog from '../../FormDialog';
import hasPermission from '../../../selectors/hasPermission';

const initialValue = {
  tracking_number: undefined,
  custom_shipping_fee: undefined,
  internal_shipping_cost: undefined
};

const validationSchema = {
  tracking_number: Yup.string().required('Required'),
  custom_shipping_fee: Yup.number().nullable(),
  internal_shipping_cost: Yup.number().nullable()
};

const PrintCompletionDialog = ({ onContinue, onClose, open, fields, initialValues, ...props }) => {
  const validation = Yup.object().shape(
    fields.reduce(
      (schema, property) => ({
        ...schema,
        [property]: validationSchema[property]
      }),
      {}
    )
  );

  const mergedInitialValues = {
    ...initialValue,
    ...initialValues
  };

  return (
    <Formik
      validationSchema={validation}
      initialValues={mergedInitialValues}
      onSubmit={onContinue}
      enableReinitialize
    >
      {({ isValid, resetForm, submitForm }) => {
        return (
          <FormDialog
            titleText="Shipping Information"
            acceptButtonText="Continue"
            {...props}
            open={open}
            handleClose={submit => {
              if (submit) {
                submitForm().then(() => {
                  resetForm();
                });
              } else {
                onClose();
                resetForm();
              }
            }}
            isAcceptButtonDisabled={!isValid}
          >
            <Form>
              <Field
                autoFocus
                id="name"
                name="tracking_number"
                label="Tracking Number"
                type="text"
                required
                fullWidth
                component={TextField}
              />
              {fields.includes('custom_shipping_fee') && (
                <Field
                  id="name"
                  name="custom_shipping_fee"
                  label="Override Shipping Fee (Optional)"
                  type="number"
                  inputProps={{
                    min: 0
                  }}
                  fullWidth
                  component={TextField}
                />
              )}
              {fields.includes('internal_shipping_cost') && (
                <Field
                  id="name"
                  name="internal_shipping_cost"
                  label="Internal Shipping Cost (Optional)"
                  type="number"
                  inputProps={{
                    min: 0
                  }}
                  fullWidth
                  component={TextField}
                />
              )}
            </Form>
          </FormDialog>
        );
      }}
    </Formik>
  );
};

PrintCompletionDialog.propTypes = {
  onContinue: PropTypes.func.isRequired,
  open: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object
};

PrintCompletionDialog.defaultProps = {
  open: false,
  initialValues: {}
};

const getAllowedFields = state => {
  const fields = ['tracking_number'];

  const customShippingFeePermission = [
    'customer-requests.manage',
    'customer-requests.apply-custom-shipping-fee'
  ];
  if (hasPermission(state, { permissions: customShippingFeePermission })) {
    fields.push('custom_shipping_fee');
  }

  const internalCostPermissions = [
    'customer-requests.manage',
    'customer-requests.apply-internal-shipping-cost'
  ];

  if (hasPermission(state, { permissions: internalCostPermissions })) {
    fields.push('internal_shipping_cost');
  }

  return fields;
};

export default connect(state => {
  return { fields: getAllowedFields(state) };
})(PrintCompletionDialog);
