import React from 'react';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import _get from 'lodash/get';
import { FILE_TYPE_IDS } from '../../../constants';

const OffHoldWrapper = ({ patient, children }) => {
  const rxForm = _get(patient, 'rx_form', null);
  const savedForLater = patient.is_on_hold && rxForm && _get(rxForm, 'saved_for_later', false);

  const getPatientFiles = fileTypeId => {
    const files = _get(patient, 'patient_files', []);

    return files.filter(patientFile => patientFile.patient_file_type_id === fileTypeId);
  };

  const getMissingFiles = () => {
    const missingFiles = [];

    if (!savedForLater) {
      return missingFiles;
    }

    if (+rxForm.scan_submission_type === 1 && getPatientFiles(FILE_TYPE_IDS.stl).length === 0) {
      missingFiles.push('Intraoral STL file');
    }

    if (+rxForm.root_integration && getPatientFiles(FILE_TYPE_IDS.dicom).length === 0) {
      missingFiles.push('DICOM file');
    }

    if (getPatientFiles(FILE_TYPE_IDS.photos).length === 0) {
      missingFiles.push('Intraoral photo');
    }

    return missingFiles;
  };

  const disabledSavedForLater = savedForLater && getMissingFiles().length;

  if (!disabledSavedForLater) {
    return typeof children === 'function' ? children({ disabled: false }) : children;
  }

  return (
    <Tooltip
      arrow
      interactive
      title={
        <>
          Missing file(s):
          {getMissingFiles().map(file => (
            <>
              <br />
              <span>- {file}</span>
            </>
          ))}
        </>
      }
    >
    <Badge color="secondary" variant="dot">
        {typeof children === 'function' ? children({ disabled: true }) : children}
      </Badge>
    </Tooltip>
  );
};

OffHoldWrapper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object.isRequired
};

export default OffHoldWrapper;
