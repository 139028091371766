import { enqueueNotification } from './notifications';
import PricingApiService from '../services/api/pricing';

// ACTION_TYPES ////////////////////////////////////////////////////////////////
export const FETCH_GLOBAL_PRICING_PREFIX = 'FETCH_GLOBAL_PRICING';
export const FETCH_GLOBAL_PRICING_REQUEST_ACTION = `${FETCH_GLOBAL_PRICING_PREFIX}_REQUEST_ACTION`;
export const FETCH_GLOBAL_PRICING_SUCCESS_ACTION = `${FETCH_GLOBAL_PRICING_PREFIX}_SUCCESS_ACTION`;
export const FETCH_GLOBAL_PRICING_FAILURE_ACTION = `${FETCH_GLOBAL_PRICING_PREFIX}_FAILURE_ACTION`;

export const UPDATE_GLOBAL_PRICING_PREFIX = 'UPDATE_GLOBAL_PRICING';
export const UPDATE_GLOBAL_PRICING_REQUEST_ACTION = `${UPDATE_GLOBAL_PRICING_PREFIX}_REQUEST_ACTION`;
export const UPDATE_GLOBAL_PRICING_SUCCESS_ACTION = `${UPDATE_GLOBAL_PRICING_PREFIX}_SUCCESS_ACTION`;
export const UPDATE_GLOBAL_PRICING_FAILURE_ACTION = `${UPDATE_GLOBAL_PRICING_PREFIX}_FAILURE_ACTION`;

// INITIAL STATE ///////////////////////////////////////////////////////////////
const initialState = {
  global: null,
  fetching: false
};

// STATE ///////////////////////////////////////////////////////////////////////
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GLOBAL_PRICING_REQUEST_ACTION:
      return {
        ...state,
        fetching: true
      };
    case FETCH_GLOBAL_PRICING_SUCCESS_ACTION:
    case UPDATE_GLOBAL_PRICING_SUCCESS_ACTION:
      return {
        ...state,
        global: action.payload
      };

    default:
      return state;
  }
};

// ACTIONS /////////////////////////////////////////////////////////////////////

export function fetchGlobalPricing() {
  return dispatch => {
    dispatch({ type: FETCH_GLOBAL_PRICING_REQUEST_ACTION });

    const pricingService = new PricingApiService();

    return pricingService
      .get()
      .then(response => {
        dispatch({ type: FETCH_GLOBAL_PRICING_SUCCESS_ACTION, payload: response.data });
      })
      .catch(error => {
        dispatch({
          type: FETCH_GLOBAL_PRICING_FAILURE_ACTION,
          payload: { message: error.message }
        });

        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function storeGlobalPricing(pricing) {
  return dispatch => {
    dispatch({ type: UPDATE_GLOBAL_PRICING_REQUEST_ACTION });

    const pricingService = new PricingApiService();
    return pricingService
      .update(pricing)
      .then(response => {
        dispatch({ type: UPDATE_GLOBAL_PRICING_SUCCESS_ACTION, payload: response.data });
        dispatch(enqueueNotification('success', response.message));
      })
      .catch(error => {
        dispatch({
          type: UPDATE_GLOBAL_PRICING_FAILURE_ACTION,
          payload: { message: error.message }
        });

        dispatch(enqueueNotification('error', error.message));
      });
  };
}

export function clearGlobalPricing() {
  return dispatch => {
    dispatch({ type: UPDATE_GLOBAL_PRICING_SUCCESS_ACTION, payload: null });
  };
}
