import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { fieldToTextField } from 'formik-material-ui';
import PropTypes from 'prop-types';

const FormikAutocomplete = ({ textFieldProps, ...props }) => {
  const {
    form: { setFieldTouched, setFieldValue }
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;
  return (
    <Autocomplete
      {...props}
      {...field}
      onChange={(_, value) => {
        setFieldValue(name, value || '');
        setFieldTouched(name, true, true);
      }}
      onBlur={() => setFieldTouched(name, true, true)}
      renderInput={inputProps => (
        <TextField {...inputProps} {...textFieldProps} helperText={helperText} error={error} />
      )}
    />
  );
};

FormikAutocomplete.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  textFieldProps: PropTypes.object,
  form: PropTypes.shape({
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired
  }).isRequired
};

FormikAutocomplete.defaultProps = {
  textFieldProps: {}
};

export default FormikAutocomplete;
